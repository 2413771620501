<template>
<div class="py-2">
  <div v-if="label" class="text-left pl-4">
    <label class="text-twine font-body text-bodyS">{{ label }}</label><br/>
  </div>
  <input :type="type" :placeholder="placeholder" :id="id" :value="value" class="inline-block w-full text-sepiaBlack dark:text-white placeholder-white placeholder-opacity-40 font-body placeholder-font-body text-bodyM placeholder-text-bodyM rounded-full border border-twine bg-halfColonialWhite bg-opacity-10 pt-2.5 pb-2 pl-4 focus:outline-none focus:ring-1 focus:ring-redDamask focus:border-transparent">
</div>

</template>

<script>
export default {
  name: 'Input',
  props: {
    label: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  data() {
    return {
      placeholderText: this.placeholder
    }
  }
}
</script>
