<template>
  	<div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
        <Header :lang="lang" 
            @langchanged="langchanged_"
            noPin>
        </Header>
        <div class="mx-6 text-center">
            <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white pt-14">Bejelentkezés</h4>
			<p id="loginError" class="hidden text-redDamask red"> Bad email or password</p>
			<p id="forgotError" class="hidden text-redDamask red"> Bad email</p>
            <Input placeholder="E-mail cím" type="email" name="email" id="email" required />
            <Input placeholder="Jelszó" type="text" id="password" name="password"/>
            <button v-on:click="forgotPassword" :disabled="btnDisabel" xl class="underline text-center min-w-max inline-block bg-transparent rounded-full text-redDamask font-body text-bodyL font-medium cursor-pointer" >
				Új jelszó elküldése emailben
			</button>
            <Button :disabledBtn="btnDisabel" xl text="Bejelentkezés" buttonType="primary" fullWidth v-on:click="login" />
            <Button href="/quick-login" :disabledBtn="btnDisabel" xl text="Regisztráció" buttonType="secondary" fullWidth />
        </div>
        <Modal @btnDisabelChange="btnDisabelChange" class="hidden" />
    </div>
</template>

<script>

import Header from "@/components/mobile/Header.vue";
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import Modal from '@/components/mobile/ForgotPasswordModal.vue';

export default {
	name: "Login",
    components: {
        Header,
        Button,
        Input,
        Modal
    },
	data(){
		return {
			username: '',
			password: '',
			btnDisabel: false,
			baseURL: process.env.VUE_APP_AXIOS_URL,
		}
	},
	 mounted() {
    },
	methods: {
		login() {
			try{
				document.querySelector("#loginError").classList.add("hidden");
				document.querySelector("#forgotError").classList.add("hidden");
				this.btnDisabel = true;
				this.email = document.querySelector('#email').value;
				this.password = document.querySelector('#password').value;
				const loginResult = this.$store.dispatch('login', { username: this.email, password: this.password});
				loginResult.then((res) => {
					if (res.error) {
						document.querySelector("#loginError").classList.remove("hidden");
					} else {
						this.$router.push('/thank-you');
					}
					this.btnDisabel = false;
				}).catch((e) => {
					this.btnDisabel = false;
					console.log(e);
					document.querySelector("#loginError").classList.remove("hidden");
				});
			}catch(e){
				console.log(e);
				document.querySelector("#loginError").classList.remove("hidden");
				this.btnDisabel = false;
			}
		},
		forgotPassword() {
			try{
				document.querySelector("#loginError").classList.add("hidden");
				document.querySelector("#forgotError").classList.add("hidden");
				this.btnDisabel = true;
				this.email = document.querySelector('#email').value;
				const post_variabels = {email: this.email, url: window.location.protocol + '//' + window.location.hostname + '/password-reset'};
				this.axios.post(this.baseURL + `/wp-json/wp/v2/forgot-password`, post_variabels, {})
            	.then(response => {
					const response_data = response.data;
					if (response_data.error) {
						this.btnDisabel = false;
						document.querySelector("#forgotError").classList.remove("hidden");
					} else {
            			document.querySelector("#popupModal").classList.remove("hidden");
					}
				}).catch( (error) => {
					console.log(error);
					this.btnDisabel = false;
				});

			}catch(e){
				console.log(e);
				document.querySelector("#forgotError").classList.remove("hidden");
				this.btnDisabel = false;
			}
		},
		langchanged_(value) {
			this.lang = value;
		},
		btnDisabelChange() {
			this.btnDisabel = false;
		},
	},
}
</script>